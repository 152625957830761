.checkout-overview {
  padding-top: 16px;

  .step {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: #8a8a8f;
      display: block;
    }

    .value {
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      display: block;
    }

    .arrow-forward {
      display: flex;
      align-items: center;
    }

    .icon svg {
      width: 8px;
      color: #ccc;
    }
  }
}
