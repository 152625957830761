.button-close {
  cursor: pointer;
  background-color: #9ea4a9;
  color: #1c1c1e;
  backdrop-filter: blur(54.3656px);
  height: 24px;
  width: 24px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 12px;
  }
}
