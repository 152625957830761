* {
  box-sizing: border-box;
}
body {
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
}
a,
a:visited {
  color: inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}
a img {
  display: block;
}
p,
dl,
dd {
  margin: 0;
}
input,
button,
textarea {
  color: inherit;
  border: none;
  font: inherit;
  background: none;
}
input:focus,
button:focus,
textarea:focus {
  outline: none;
}
input::placeholder,
button::placeholder,
textarea::placeholder {
  opacity: 0.3;
  color: inherit;
}
input:not([type="checkbox"]),
button:not([type="checkbox"]),
textarea:not([type="checkbox"]) {
  -webkit-appearance: none;
}
em {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: inherit;
  line-height: 1.2;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
[data-rsbs-header] {
  padding: 12px 15px !important;
  box-shadow: none !important;
}
body {
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.6;
}
body,
html {
  height: 100%;
}
main {
  min-height: 60vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}
em {
  font-style: italic;
  font-weight: 300;
}
p a {
  color: #007aff;
}
.upper {
  text-transform: uppercase;
}
.xs {
  font-size: 12px;
}
.sm {
  font-size: 14px;
}
.lg {
  font-size: 24px;
  line-height: 1.2;
}
.xl {
  font-size: 32px;
  line-height: 1.2;
}
.xxl {
  font-size: 54px;
  line-height: 1.2;
}
.faded {
  opacity: 0.5;
}
@media only screen and (min-width: 768px) {
  .pushed {
    margin-left: 8vw;
  }
}
.bold {
  font-weight: bold;
}
.dark {
  color: #fcfdfe;
  -webkit-font-smoothing: antialiased;
}
.narrow {
  max-width: 360px;
  margin: 0 auto;
}
.bg-black {
  background-color: #030303;
}
.bg-beige {
  background-color: #efebe7;
}
.link {
  color: #007aff !important;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .desktop {
    display: none !important;
  }
}
@media only screen and (max-width: 379px) {
  .desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1127px) {
  .mobile {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}
input:-webkit-autofill::first-line {
  font-size: 18px;
  font-family: 'aktiv-grotesk';
}
