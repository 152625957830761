.event-share {
  padding: 20px;
  padding-top: 10px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button-close {
    margin-top: -10px;
    margin-right: -10px;
  }

  .button.primary {
    background-color: #fcfdfe;
    color: #050a0d;
  }

  .creator__image {
    border: 1px solid #e6e6e6;
    height: 50px;
    width: 50px;
    object-fit: cover;
    display: block;
    border-radius: 40px;
    margin-bottom: 20px;
  }

  .creator__name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  &__description {
    margin-bottom: 20px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    margin-bottom: 14px;
  }

  .products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    img {
      width: 100%;
    }
    margin-bottom: 2em;
  }
}
