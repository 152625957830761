.product-overview {
  z-index: 0;
  position: relative;
  min-height: 100%;
  height: 100vh;
  padding-bottom: 5%;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  &__preOrder {
    margin-top: 0.5em;
    color: #fcfdfe;
    background: #3f82aa;
    display: inline-block;
    border-radius: 35px;
    padding: 4px 14px;
  }

  &__preOrderMessage {
    margin-top: 14px;
    color: #3f82aa;
    text-align: center;
  }

  &__header {
    padding: 15px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .icon {
      display: block;
      svg {
        width: 15px;
        height: 24px;
      }
    }
  }

  &__price {
    margin-top: 4px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    &.original {
      text-decoration: line-through;
      color: #9b9b9b;
    }
  }

  &__title {
    margin-top: 6px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 3px;
  }

  &__images {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    gap: 10px;
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;

    img {
      border: 1px solid #ccc;
      border-radius: 6px;
      width: 250px;
      min-width: 250px;
      height: 250px;
      object-fit: cover;
    }
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.408px;

    overflow: hidden;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 69px;

    p {
      display: inline;
    }

    &.show-all {
      -webkit-line-clamp: initial;
      max-height: 100%;

      p {
        display: block;
      }
    }
  }

  .select {
    margin-top: 10px;
  }

  .add-to-cart {
    margin-top: 10px;
  }

  .readmore {
    font-size: 14px;
    line-height: 23px;
    font-weight: bold;
  }

  .button.primary {
    background: #fcfdfe;
    color: #050a0d;
    border-radius: 100px;
  }

  &__details-link {
    cursor: pointer;
    margin-top: 30px;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    .icon {
      margin-top: 3px;
      width: 12px;
    }
  }
}
